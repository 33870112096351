/** * Created by litian on 2019-04-01. */
<style lang="less">
@border-color: #ccc;
.info-window-ul {
  & > div {
    list-style: none;
    span {
      display: inline-block;
      width: 60px;
      text-align: right;
    }
  }
}
.maker-title {
  width: 70px;
  line-height: 20px;
  text-align: center;
  border: 1px solid @border-color;
  background: #fff;
  position: relative;
  top: -5px;
  border-radius: 2px;
  &:after {
    content: '';
    height: 8px;
    width: 8px;
    border-top: 1px solid @border-color;
    border-right: 1px solid @border-color;
    box-sizing: border-box;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 -5px -4px;
    transform: rotate(135deg);
  }
}
.amap-marker-label {
  border: none !important;
  background: transparent;
}
</style>

<template> </template>

<script>
export default {
  data() {
    return {
      map: null,
      locateTypeIcon: {},
      statusIcon: {},
    }
  },
  mounted() {
    this.initMap()
    this.initIconConfig()
  },
  methods: {
    initMap() {
      let vm = this
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/43bfd945d0b1dca78317a540283599ee',
      })
      map.setDefaultCursor('pointer')
      this.map = map
      //加载PathSimplifier，loadUI的路径参数为模块名中 'ui/' 之后的部分
      AMapUI.load(['ui/misc/PathSimplifier'], function(PathSimplifier) {
        if (!PathSimplifier.supportCanvas) {
          alert('当前浏览器不支持 Canvas！请更换！')
          return
        }

        //启动页面
        //轨迹实例
        vm.pathSimplifierIns = new PathSimplifier({
          zIndex: 100,
          map: map, //所属的地图实例
          getPath: function(pathData, pathIndex) {
            //返回轨迹数据中的节点坐标信息，[AMap.LngLat, AMap.LngLat...] 或者 [[lng|number,lat|number],...]
            return pathData.path.map(function(item) {
              return [item.lng, item.lat]
            })
          },
          getHoverTitle: function(pathData, pathIndex, pointIndex) {
            //返回鼠标悬停时显示的信息
            if (pointIndex >= 0) {
              //鼠标悬停在某个轨迹节点上
              return null
            }
            //鼠标悬停在节点之间的连线上
            return null
          },
          renderOptions: {
            renderAllPointsIfNumberBelow: 500, //绘制点最大阈值
            //轨迹线的样式
            pathLineStyle: {
              strokeStyle: 'blue',
              lineWidth: 4,
              borderWidth: 0,
              dirArrowStyle: false,
            },
            pathLineHoverStyle: null,
            pathLineSelectedStyle: {
              strokeStyle: 'blue',
            },
            pathNavigatorStyle: {
              width: 29,
              height: 52,
              content: PathSimplifier.Render.Canvas.getImageContent(
                '../asset/img/truck.png',
                function onload() {
                  //图片加载成功，重新绘制一次
                  vm.pathSimplifierIns.renderLater()
                },
                function onerror(e) {
                  alert('图片加载失败！')
                },
              ),
              strokeStyle: null,
              fillStyle: null,
              pathLinePassedStyle: {
                strokeStyle: 'green',
                lineWidth: 4,
                borderWidth: 0,
              },
            },
          },
        })
      })
    },
    initIconConfig() {
      //定位类型图标
      this.locateTypeIcon = {
        1: new AMap.Icon({
          size: new AMap.Size(24, 30),
          image: 'https://oss.kuaihuoyun.com/xhk-bops/gps.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 30),
        }),
        2: new AMap.Icon({
          size: new AMap.Size(24, 30), // 图标尺寸
          image: 'https://oss.kuaihuoyun.com/xhk-bops/base.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 30),
        }),
        4: new AMap.Icon({
          size: new AMap.Size(24, 30), // 图标尺寸
          image: 'https://oss.kuaihuoyun.com/xhk-bops/wifi.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 30),
        }),
      }
      this.locateTypeIcon[5] = this.locateTypeIcon[1] //GPS和北斗定位
      this.locateTypeIcon[6] = this.locateTypeIcon[4] //WIFI定位
      //设备状态图标
      this.statusIcon = {
        1: new AMap.Icon({
          size: new AMap.Size(24, 30),
          image: '../asset/img/map/online.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 30),
        }),
        2: new AMap.Icon({
          size: new AMap.Size(24, 30), // 图标尺寸
          image: '../asset/img/map/offline.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 30),
        }),
        3: new AMap.Icon({
          size: new AMap.Size(24, 30), // 图标尺寸
          image: 'https://oss.kuaihuoyun.com/xhk-bops/wifi.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 30),
        }),
      }
      //设备异常点
      this.abnormalIcon = {
        //停止点
        1: new AMap.Icon({
          size: new AMap.Size(24, 45),
          image: '../asset/img/map/stop.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 45),
        }),
        //光感报警点
        2: new AMap.Icon({
          size: new AMap.Size(24, 45),
          image: '../asset/img/map/stop.png',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(24, 45),
        }),
      }
      //ExtremePoint 起终点
      this.extremePointIcon = {
        1: new AMap.Icon({
          size: new AMap.Size(40, 40),
          image:
            'https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/fahuodian.svg',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(40, 40),
        }),
        2: new AMap.Icon({
          size: new AMap.Size(40, 40), // 图标尺寸
          image:
            'https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/shouhuodian.svg',
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(40, 40),
        }),
      }
    },
  },
}
</script>
