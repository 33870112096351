/**
 * Created by litian on 2019-06-22.
 */
'use strict';
import {_axios} from '../plugins/axios';
import {ajax} from '../libs/util';

export function getLocation(device,cb) {
    device.loading = true;
    ajax.post('/device/get_location', {
        service: '/device/get_location',
        device: device.device,
        lat:device.lat,
        lng:device.lng,
        locateTime: device.locateTime 
    }, res=> {
        device.loading = false;
        if(res.data && res.data.address){
            device.address = res.data.address;
            device.lat = res.data.lat;
            device.lng = res.data.lng;
        }
        cb && cb(null,device);
    },
    err => {
        console.log('err:',err);
        device.loading = false;
        cb && cb(err,device);
    })
}

export function getAddressNode(device){
    return device.address ? device.address : `<a href="javascript:;" onclick="getDeviceLocation('${device.device}',${device.locateTime})">查看地址</a>`
}
